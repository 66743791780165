<template>
  <svg
    v-if="type === 'default'"
    :width="width"
    :height="height"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.4458 6.6665L16.9925 14.1198L9.53918 6.6665L7.65918 8.5465L15.1125 15.9998L7.65918 23.4532L9.53918 25.3332L16.9925 17.8798L24.4458 25.3332L26.3258 23.4532L18.8725 15.9998L26.3258 8.5465L24.4458 6.6665Z"
      :fill="fill"
    />
  </svg>

  <svg
    v-else-if="type === 'circle'"
    :width="width"
    :height="height"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5001 27.4359C9.9629 27.4391 6.56966 26.0354 4.06848 23.5342C1.56731 21.033 0.163571 17.6398 0.166753 14.1026V13.8359C0.275804 8.49223 3.56382 3.72948 8.5219 1.73335C13.48 -0.262775 19.1509 0.893129 22.9321 4.67061C26.7487 8.48405 27.891 14.2217 25.8259 19.2061C23.7608 24.1905 18.8954 27.4391 13.5001 27.4359ZM13.5001 15.9826L16.9534 19.4359L18.8334 17.5559L15.3801 14.1026L18.8334 10.6493L16.9534 8.76927L13.5001 12.2226L10.0468 8.76927L8.16676 10.6493L11.6201 14.1026L8.16676 17.5559L10.0468 19.4359L13.5001 15.9839V15.9826Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconsClose',

  props: {
    type: {
      type: String,
      default: 'default'
    },

    fill: {
      type: String,
      default: '#193560'
    },

    width: {
      type: [String, Number],
      default: '33'
    },

    height: {
      type: [String, Number],
      default: '32'
    }
  }
}
</script>
